<template>
    <div style="padding: 10px 10px">
      <div ref="flowsStepRef" >
        <div style="margin: 10px 0px;">
          <a-steps  size="small" >
            <template v-for="(item,index) in workOrderFlowsSteps" :key="index">
              <a-step status="finish">
                <template #title>{{flowTypeObj[item.flowType]}}</template>
                <template #description>
                  <div>操作人：{{ item.senderId }}</div>
                  <div>时间：{{ item.operationTime }}</div>
                </template>
              </a-step>
            </template>
          </a-steps>
        </div>
      </div>

      <div style="margin-top: 20px;text-align: right;">
        <a-space align="baseline">
          <a-button v-if="but_assign_visible"  type="primary"  @click="assign_dialog_visible = true"><template #icon><arrow-right-outlined /></template>指派</a-button>
          <a-button v-if="but_receiver_visible"  type="primary"  @click="receive_dialog_visible = true"><template #icon><down-outlined /></template>接单</a-button>
          <a-button v-if="but_reject_visible" type="primary"  @click="reject_dialog_visible = true"><template #icon><stop-outlined /></template>拒单</a-button>
          <a-button v-if="but_delay_visible"  type="primary"  @click="delay_dialog_visible = true"><template #icon><clock-circle-outlined /></template>延时</a-button>
          <a-button v-if="but_transfer_visible"  type="primary"  @click="transfer_dialog_visible = true"><template #icon><retweet-outlined /></template>转单</a-button>
          <a-button v-if="but_cooperate_visible"  type="primary"  @click="cooperate_dialog_visible = true"><template #icon><team-outlined /></template>协同</a-button>
          <a-button v-if="but_finished_visible" type="primary" @click="finish_dialog_visible = true"><template #icon><check-circle-outlined /></template>完成</a-button>
          <a-button v-if="but_comment_visible" type="primary" @click="comment_dialog_visible = true"><template #icon><meh-outlined /></template>去评价</a-button>
          <a-button v-if="but_cancel_visible" type="primary" danger @click="cancelWorkOrder"><template #icon><close-outlined /></template>撤销</a-button>
        </a-space>

      </div>

      <div style="margin-top: 20px;">
        <a-descriptions  size="small" :column="1" style="" bordered>
          <template #title><div class="desc_title">报障信息</div></template>
          <a-descriptions-item>
            <template #label>
              <div style="width: 60px;">单号</div>
            </template>
            {{workOrder.orderNo}}
          </a-descriptions-item>
          <a-descriptions-item label="故障概述">{{workOrder.faultOverview}}</a-descriptions-item>
          <a-descriptions-item label="创建人">{{workOrder.createUser?.name}}</a-descriptions-item>
          <a-descriptions-item label="创建时间">{{workOrder.createTime}}</a-descriptions-item>
          <a-descriptions-item label="报障部门">{{workOrder.dept?.name}}</a-descriptions-item>
          <a-descriptions-item label="报障人">{{workOrder.reportUser?.name}}</a-descriptions-item>
          <a-descriptions-item label="预约时间">{{workOrder.importanceLevel === 0 ? '立即' : workOrder.expectedTime}}</a-descriptions-item>
          <a-descriptions-item label="联系方式">{{workOrder.contactInfo}}</a-descriptions-item>
          <a-descriptions-item label="地址">{{workOrder.addr}}</a-descriptions-item>
          <a-descriptions-item label="等级">
            <a-tag v-if="workOrder.importanceLevel === 0" color="error">紧急</a-tag>
            <a-tag v-else-if="workOrder.importanceLevel === 1" color="warning">重要</a-tag>
            <a-tag v-else-if="workOrder.importanceLevel === 2" color="processing">一般</a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="附件">
            <template v-for="(item,index) in workOrder?.workOrderAttachFiles" :key="index">
              <template v-if="item.type === 0">
                <a-button style="padding: 0;" type="link" v-if="fileUpload.isImgSuffix(item.name)===true" @click="()=>showPreviewImg(item.name,item.path)"><template #icon><file-image-outlined /></template>{{ item.name }}</a-button>
                <a-button style="padding: 0;" type="link" v-else @click="showOtherFile(item.name,item.path)"><template #icon><paper-clip-outlined /></template>{{ item.name }}</a-button>
                <br/>
              </template>
            </template>
          </a-descriptions-item>
        </a-descriptions>
      </div>

      <div style="margin-top: 20px;">
        <a-descriptions  size="small" :column="1" style="" bordered>
          <template #title><div class="desc_title">处理信息</div></template>
          <a-descriptions-item>
            <template #label>
              <div style="width: 60px;">处理人</div>
            </template>
            {{chuliUser}}
          </a-descriptions-item>
          <!--        <a-descriptions-item label="协同人">
                    <template v-for="(item,index) in xietongUser" :key="index">
                      <a-tag color="blue">{{ item }}</a-tag>
                    </template>
                  </a-descriptions-item>-->
          <a-descriptions-item label="预计时间">{{plannedDate}}</a-descriptions-item>
          <a-descriptions-item label="完成时间">{{workOrder?.finishTime}}</a-descriptions-item>
          <a-descriptions-item label="拒单原因">
            <template v-for="(item,index) in rejectCause" :key="index">
              {{ item }} <br/>
            </template>
          </a-descriptions-item>
          <a-descriptions-item label="转单原因">
            <template v-for="(item,index) in zhuandanCause" :key="index">
              {{ item }} <br/>
            </template>
          </a-descriptions-item>
          <a-descriptions-item label="延时原因">
            <template v-for="(item,index) in yanshiCause" :key="index">
              {{ item }} <br/>
            </template>
          </a-descriptions-item>
          <a-descriptions-item label="故障类型">{{workOrder?.faultType?.name}}</a-descriptions-item>
          <a-descriptions-item label="关联资产">
            <a-popover>
              <template #title><gold-outlined />&nbsp;资产信息</template>
              <template #content>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;状态：&nbsp;
                  <a-tag color="error" v-if="workOrder?.asset?.status === Constant.ASSET_STATUS_CANCELED">已核销</a-tag>
                  <a-tag color="green" v-else-if="workOrder?.asset?.status === Constant.ASSET_STATUS_UNUSED">未使用</a-tag>
                  <a-tag color="orange" v-else-if="workOrder?.asset?.status === Constant.ASSET_STATUS_USED">已使用</a-tag>
                </div>
                <div>资产编号：&nbsp;{{workOrder?.asset?.assetNo}}</div>
                <div>资产类型：&nbsp;{{workOrder?.asset?.assetType?.name}}</div>
                <div>资产品牌：&nbsp;{{workOrder?.asset?.assetBrand?.name}}</div>
                <div>资产型号：&nbsp;{{workOrder?.asset?.assetModel}}</div>
                <div>购买时间：&nbsp;{{workOrder?.asset?.buyTime}}</div>
                <div>&nbsp;&nbsp;&nbsp;使用人：&nbsp;{{workOrder?.asset?.user?.name}}</div>
                <div>领取时间：&nbsp;{{workOrder?.asset?.receiveTime}}</div>
              </template>
              <a>{{workOrder?.asset?.assetName}}</a>
            </a-popover>
          </a-descriptions-item>
          <a-descriptions-item label="解决方案">{{workOrder?.workOrderFaq?.solution}}</a-descriptions-item>
          <a-descriptions-item label="方案附件">
            <template v-for="(item,index) in workOrder?.workOrderAttachFiles" :key="index">
              <template v-if="item.type === 1">
                <a-button style="padding: 0;" type="link" v-if="fileUpload.isImgSuffix(item.name)===true" @click="()=>showPreviewImg(item.name,item.path)"><template #icon><file-image-outlined /></template>{{ item.name }}</a-button>
                <a-button style="padding: 0;" type="link" v-else @click="showOtherFile(item.name,item.path)"><template #icon><paper-clip-outlined /></template>{{ item.name }}</a-button>
                <br/>
              </template>
            </template>
          </a-descriptions-item>
          <a-descriptions-item>
            <template #label>
              <div>工单历时<a-tooltip color="orange" title="工单发起时间到工单结束时间之间的时间"><ExclamationCircleOutlined style="color: orange;margin-left: 5px"/></a-tooltip></div>
            </template>
            {{dateUtil.parseTwoDateToDay(workOrder.createTime,workOrder.finishTime)}}
          </a-descriptions-item>
          <a-descriptions-item>
            <template #label>
              <div>处理用时<a-tooltip color="orange" title="工单接单时间到工单结束时间之间的时间"><ExclamationCircleOutlined style="color: orange;margin-left: 5px"/></a-tooltip></div>
            </template>
            {{dateUtil.parseTwoDateToDay(workOrder.receiveTime,workOrder.finishTime)}}
          </a-descriptions-item>
        </a-descriptions>
      </div>

      <div v-if="comment.id" style="margin-top: 20px;">
        <a-descriptions  size="small" :column="1" style="" bordered>
          <template #title><div class="desc_title">客户评价</div></template>
          <a-descriptions-item>
            <template #label>
              <div style="width: 80px;">满意度</div>
            </template>
            {{comment.codeMap?.value}}
          </a-descriptions-item>
          <a-descriptions-item label="评价标签">
            <template v-for="tag in comment.tags" :key="tag">
              <a-tag color="#fcf2dc" style="color: #e18201;">{{tag}}</a-tag>
            </template>
          </a-descriptions-item>
          <a-descriptions-item label="评价内容">{{comment.content}}</a-descriptions-item>
          <a-descriptions-item label="客户">{{comment.user?.name}}</a-descriptions-item>
        </a-descriptions>
      </div>

      <!--    指派弹窗     -->
      <a-modal
          v-model:visible="assign_dialog_visible"
          title="指派"
          ok-text="确认"
          cancel-text="取消"
          @ok="onAssignOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <a-spin :spinning="loading">
          <a-form ref="formAssignRef" :model="formAssignState" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-form-item label="接收人" name="receiver" :rules="[{ required: true, message: '请选择接收人' ,trigger:'blur'}]">
              <CustomInputDeptUser :defultValue="formAssignState.receiver" @callbackSelVal="callback_selDeptUser_assign" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
      <!--    拒单弹窗     -->
      <a-modal
          v-model:visible="reject_dialog_visible"
          title="拒单"
          ok-text="确认"
          cancel-text="取消"
          @ok="onRejectOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <a-spin :spinning="loading">
          <a-form ref="formRejectRef" :model="formRejectState" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
            <a-form-item label="拒单原因" name="cause" :rules="[{ required: true, message: '请输入拒单原因',trigger:'blur' },{ max:100, message: '拒单描述长度不能超过100个字符',trigger:'blur' }]">
              <a-textarea v-model:value="formRejectState.cause" size="default" show-count :maxlength="100" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
      <!--    接单弹窗     -->
      <a-modal
          v-model:visible="receive_dialog_visible"
          title="接单"
          ok-text="确认"
          cancel-text="取消"
          @ok="onReceiveOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <a-spin :spinning="loading">
          <a-form ref="formReceiveRef" :model="formReceiveState" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-form-item label="预计解决时间" name="plannedDate" :rules="[{ required: true, message: '请输入有效日期' }]">
              <a-date-picker v-model:value="formReceiveState.plannedDate" format="YYYY-MM-DD HH:mm:ss" :locale="locale" size="default"/>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

      <!--    延时弹窗     -->
      <a-modal
          v-model:visible="delay_dialog_visible"
          title="延时"
          ok-text="确认"
          cancel-text="取消"
          @ok="onDelayOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <a-spin :spinning="loading">
          <a-form ref="formDelayRef" :model="formDelayState" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-form-item label="预计解决时间" name="plannedDate" :rules="[{ required: true, message: '请输入有效日期' }]">
              <a-date-picker v-model:value="formDelayState.plannedDate" format="YYYY-MM-DD HH:mm:ss" :locale="locale" size="default"/>
            </a-form-item>
            <a-form-item label="延时原因" name="cause" :rules="[{ required: true, message: '请输入延时原因',trigger:'blur' },{ max:100, message: '故障描述长度不能超过100个字符',trigger:'blur' }]">
              <a-textarea v-model:value="formDelayState.cause" size="default" show-count :maxlength="100" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

      <!--    转单弹窗     -->
      <a-modal
          v-model:visible="transfer_dialog_visible"
          title="转单"
          ok-text="确认"
          cancel-text="取消"
          @ok="onTransferOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <a-spin :spinning="loading">
          <a-form ref="formTransferRef" :model="formTransferState" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-form-item label="接收人" name="receiver" :rules="[{ required: true, message: '请选择接收人' ,trigger:'blur'}]">
              <CustomInputDeptUser :defultValue="formTransferState.receiver" @callbackSelVal="callback_selDeptUser" />
            </a-form-item>
            <a-form-item label="转单类型" name="transferType" :rules="[{ required: true, message: '请选择转单类型' ,trigger:'blur'}]">
              <a-select
                  v-model:value="formTransferState.transferType"
                  show-search
                  :options="selectAllTransferTypeOptions"
                  placeholder="转单类型选择"
                  @focus="findAllTransferOrderType"
              >
                <template v-if="selectAllTransferTypeOptions" #notFoundContent>
                  <a-spin size="small" />
                </template>
              </a-select>
            </a-form-item>
            <a-form-item label="转单原因" name="cause" :rules="[{ required: true, message: '请输入转单原因',trigger:'blur' },{ max:100, message: '转单原因长度不能超过100个字符',trigger:'blur' }]">
              <a-textarea v-model:value="formTransferState.cause" size="default" show-count :maxlength="100" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

      <!--    协同弹窗     -->
      <a-modal
          v-model:visible="cooperate_dialog_visible"
          title="协同"
          ok-text="确认"
          cancel-text="取消"
          @ok="onCooperateOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <a-spin :spinning="loading">
          <a-form ref="formCooperateRef" :model="formCooperateState" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-form-item label="协同人" name="receiver" :rules="[{ required: true, message: '请选择协同人' ,trigger:'blur'}]">
              <CustomInputDeptUser :defultValue="formCooperateState.receiver" @callbackSelVal="callback_selDeptUserOfxietong" />
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

      <!--    完成弹窗     -->
      <a-modal
          v-model:visible="finish_dialog_visible"
          title="工单完成"
          ok-text="确认"
          cancel-text="取消"
          @ok="onFinishOK"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <a-spin :spinning="loading">
          <a-form ref="formFinishRef" :model="formFinishState" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-form-item label="关联的资产">
              <a-button v-if="!finishSelectAsset.id" @click="showListAssetDialog"><template #icon><gold-outlined /></template>选择资产</a-button>
              <a-tag v-if="finishSelectAsset.id" closable @close="()=>{finishSelectAsset.id = null;finishSelectAsset.assetName = null;}">{{finishSelectAsset.assetName}}</a-tag>
            </a-form-item>
            <a-form-item label="故障类型" name="faultTypeId" :rules="[{ required: true, message: '请选择故障类型' ,trigger:'blur'}]">
              <a-tree-select
                  v-model:value="formFinishState.faultTypeId"
                  show-search
                  style="width: 80%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择"
                  allow-clear
                  :tree-data="treeSelectAllFaultType"
                  tree-default-expand-all
                  @focus="findAllFaultType"
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item label="解决方案" name="solution" :rules="[{ required: true, message: '请输入解决方案',trigger:'blur' },{ max:200, message: '解决方案长度不能超过200个字符',trigger:'blur' }]">
              <a-textarea v-model:value="formFinishState.solution" size="default" show-count :maxlength="200" />
            </a-form-item>
            <a-form-item label="上传文件">
              <a-upload :file-list="fileList" :before-upload="beforeUpload" @remove="handleFileRemove" :max-count="5">
                <a-button><template #icon><upload-outlined/></template>选择文件</a-button>
              </a-upload>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

      <!--    评价弹窗     -->
      <a-modal
          v-model:visible="comment_dialog_visible"
          title="您对本次服务满意吗？"
          ok-text="确认"
          cancel-text="取消"
          @ok="onCommentOK"
          :ok-button-props="{ disabled: formCommentState.code ? false : true }"
          :confirmLoading="false"
          :destroy-on-close="true"
      >
        <a-spin :spinning="loading">
          <a-form ref="formCommentRef" :model="formCommentState" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
            <a-form-item name="code" :wrapper-col="{ offset: 7, span: 15 }">
              <a-space align="center" style="text-align: center;">
                <a-button type="text" size="large" @click="setCommentCode(Constant.COMMENT_CODE_OK)">
                  <template #icon>
                    <template v-if="formCommentState.code === Constant.COMMENT_CODE_OK"><smile-two-tone two-tone-color="#f38000" style="font-size: 28px;"/></template>
                    <template v-else><smile-outlined two-tone-color="#f38000"/></template>
                  </template>
                  <span :style="formCommentState.code === Constant.COMMENT_CODE_OK ? 'color:#f38000;' : ''">满意</span>
                </a-button>
                <a-button type="text" size="large" @click="setCommentCode(Constant.COMMENT_CODE_BAD)">
                  <template #icon>
                    <template v-if="formCommentState.code === Constant.COMMENT_CODE_BAD"><frown-two-tone two-tone-color="#ff2e11" style="font-size: 28px;"/></template>
                    <template v-else><frown-outlined  two-tone-color="#ff2e11"/></template>
                  </template>
                  <span :style="formCommentState.code === Constant.COMMENT_CODE_BAD ? 'color:#ff2e11;' : ''">不满意</span>
                </a-button>
                <a-input v-model:value="formCommentState.code" style="display: none"/>
              </a-space>
            </a-form-item>
            <transition mode="out-in" leave-active-class="cmp-out" enter-active-class="cmp-in">
              <a-form-item v-if="formCommentState.code" :wrapper-col="{ offset: 0, span: 24 }">
                <template  v-for="tag in formCommentState.code === Constant.COMMENT_CODE_OK ? commentTagState.okTags : commentTagState.badTags" :key="tag">
                  <a-checkable-tag
                      :checked="commentTagState.selectedTags.indexOf(tag) > -1"
                      @change="checked => handleCommentTagChange(tag, checked)"
                      style="margin: 5px;"
                  >
                    {{ tag }}
                  </a-checkable-tag>
                </template>
                <a><a-tag v-if="formCommentState.code === Constant.COMMENT_CODE_OK" color="#55acee" @click="comment_content_visible = !comment_content_visible"><template #icon><template v-if="comment_content_visible"><close-outlined /></template><template v-else><edit-outlined /></template></template>写评价</a-tag></a>
              </a-form-item>
            </transition>
            <transition mode="out-in" leave-active-class="cmp-out" enter-active-class="cmp-in">
              <a-form-item v-if="comment_content_visible" name="content" :rules="[{ max:200, message: '评价内容长度不能超过200个字符',trigger:'blur' }]" :wrapper-col="{ offset: 0, span: 24 }">
                <a-textarea v-model:value="formCommentState.content" :rows="4" size="default" show-count :maxlength="200" :placeholder="formCommentState.code === Constant.COMMENT_CODE_OK ? '您的肯定激励我们做的更好' : '您的反馈会督促我们做的更好'"/>
              </a-form-item>
            </transition>
          </a-form>
        </a-spin>
      </a-modal>

      <!--    <a-image
              :width="200"
              :style="{ display: 'none' }"
              :preview="{
                visible,
                onVisibleChange: setVisiblePreviewImg,
              }"
              src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
          />-->
      <ImgPreview ref="imgPreviewRef"></ImgPreview>
      <ListAssetDialog ref="listAssetDialogRef" @callbackSelAsset="callbackSelAsset"/>

    </div>
</template>

<script setup>

import {computed, createVNode, defineAsyncComponent, nextTick, onMounted, reactive, ref} from "vue";
import {
  ArrowRightOutlined,
  ClockCircleOutlined,
  RetweetOutlined,
  TeamOutlined,
  CheckCircleOutlined,
  UploadOutlined,
  FileImageOutlined,
  PaperClipOutlined,
  MehOutlined,
  SmileOutlined,
  SmileTwoTone,
  FrownTwoTone,
  FrownOutlined,
  EditOutlined,
  CloseOutlined,
  GoldOutlined,
  ExclamationCircleOutlined,
  StopOutlined,
  DownOutlined,
} from "@ant-design/icons-vue";
import api_workOrder from "@/api/workOrder";
import {message, Modal} from "ant-design-vue";
import {Constant, fileUpload, flowTypeObj} from "@/js/common";
import clone from "clone";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import api_faultType from "@/api/faultType";
import api_transferOrderType from "@/api/transferOrderType";
import {dateUtil} from "@/js/utils";
dayjs.locale('zh-cn');
import {useRoute} from "vue-router";
const route = useRoute();

/** 异步加载组件 **/
const ImgPreview = defineAsyncComponent(() =>{
  return import('@/components/_imgPreview')
})
const CustomInputDeptUser = defineAsyncComponent(() =>{
  return import('@/components/_customInputDeptUser.vue')
})
const ListAssetDialog = defineAsyncComponent(() =>{
  return import('@/components/_listAssetDialog.vue')
})

//const props = defineProps({workOrderId:String}); //defineProps声明的props的值是只读readonly的，不能修改


const loading = ref(false);
const visible_viewWorkOrder = ref(false);
const isDataChanged = ref(false);         //在此页面是否操作了其他功能，数据发生变化了
const workOrder_id = ref();
const workOrder = ref({});
const workOrderFlows = ref([]);
const workOrderFlowsSteps = ref([]);
const flowsStepRef = ref();

const assign_dialog_visible = ref(false);
const reject_dialog_visible = ref(false);
const receive_dialog_visible = ref(false);
const delay_dialog_visible = ref(false);
const transfer_dialog_visible = ref(false);
const cooperate_dialog_visible = ref(false);
const finish_dialog_visible = ref(false);
const comment_dialog_visible = ref(false);
const comment_content_visible = ref(false);
const formAssignRef = ref();
const formRejectRef = ref();
const formReceiveRef = ref();
const formDelayRef = ref();
const formTransferRef = ref();
const formCooperateRef = ref();
const formFinishRef = ref();
const formCommentRef = ref();
const selectAllTransferTypeOptions = ref([]);
const fileList = ref([]);
const imgPreviewRef = ref();
const listAssetDialogRef = ref();
const but_assign_visible  = ref(false);       //指派按钮的visible
const but_reject_visible = ref(false);        //拒单按钮的visible
const but_receiver_visible = ref(false);      //接单按钮的visible
const but_transfer_visible = ref(false);      //转单按钮的visible
const but_delay_visible = ref(false);         //延迟按钮的visible
const but_cooperate_visible = ref(false);     //协同按钮的visible
const but_finished_visible = ref(false);      //完成按钮的visible
const but_comment_visible = ref(false);       //评价按钮
const but_cancel_visible = ref(false);        //撤销
const formAssignState = ref({
  receiver:"",
})
const formRejectState = ref({
  cause:""
});
const formReceiveState = ref({
  plannedDate:"",
});
const formDelayState = ref({
  plannedDate:"",
  cause:""
});
const formTransferState = ref({
  receiver:"",
  transferType:"",
  cause:""
});
const formCooperateState = ref({
  receiver:[],
});
const formFinishState = ref({
  faultTypeId:'',
  solution:"",
});
const formCommentState = ref({
  code: "",
  content: "",
  workOrderId: ""
})
const treeSelectAllFaultType =  ref([]);
const commentTagState = reactive({
  okTags: ['快速准时', '礼貌热情', '风雨无阻', '沟通顺畅','技术娴熟', '神准时', '值得称赞', '还可以','积极主动', '能力强'],
  badTags: ['时间超时', '态度不好', '迫不及待', '缺乏效率','敷衍了事', '不积极', '不负责任', '不专业','散漫', '其他'],
  selectedTags: [],
});

const comment = ref({
  id:null,
  codeMap:null,
  tags:'',
  content: '',
  createTime: '',
  user: ''
});

const finishSelectAsset = ref({
  id: null,
  assetName: ""
})

onMounted(()=>{
  if (route.query.workOrderId){
    show(route.query.workOrderId);
  }
})

const show = (id)=>{
  workOrder_id.value = id;
  visible_viewWorkOrder.value = true;
  getItem(id);
}

const getItem = (workOrderId)=>{
  api_workOrder.getWorkOrder(workOrderId).then(res=>{
    if (res?.data?.code === 0) {
      //console.log("getItem--->",res);
      workOrder.value = res.data.data;
      const commentObj = res.data.data.comment;
      const woAsset = res.data.data.asset;
      if (commentObj){
        comment.value.id = commentObj.id;
        comment.value.codeMap = commentObj.codeMap;
        comment.value.tags = JSON.parse(commentObj.tags);
        comment.value.content = commentObj.content;
        comment.value.createTime = commentObj.createTime;
        comment.value.user = commentObj.user;
      }
      if (woAsset){
        finishSelectAsset.value.id = woAsset.id;
        finishSelectAsset.value.assetName = woAsset.assetName;
      }

      workOrderFlows.value = res.data.data.workOrderFlows;
      const tmp = clone(res.data.data.workOrderFlows);
      workOrderFlowsSteps.value = tmp;
      doButStatus();
      /** 滚动到到最新的流程位置 **/
      nextTick((()=>{
        flowsStepRef.value.scrollTo({left:flowsStepRef.value.scrollWidth,behavior:'smooth'});
      }))
    }
  });
}

const doButStatus = ()=>{
  const fda = workOrder.value.flowDoAuth;
  if (fda){
    but_assign_visible.value = fda.assign_visible;         //指派按钮
    but_reject_visible.value = fda.reject_visible;         //拒单按钮
    but_receiver_visible.value = fda.receiver_visible;       //接单按钮
    but_transfer_visible.value = fda.transfer_visible;       //转单按钮
    but_delay_visible.value = fda.delay_visible;          //延迟按钮
    but_finished_visible.value = fda.finished_visible;       //完成按钮
    but_comment_visible.value = fda.comment_visible;        //评价按钮
    but_cancel_visible.value = fda.cancel_visible;         //撤销按钮
  }
}

/** 获取处理人名字 **/
const chuliUser = computed(()=>{
  if (workOrder.value.pendingUser){
    return workOrder.value.pendingUser.name;
  }else {
    return "";
  }
})
/** 获取协同人名字 **/
/*const xietongUser = computed(()=>{
  let names = [];
  if (workOrderFlows.value.length > 0){
    const flows =  workOrderFlows.value.filter(item=>item.flowType === 6);
    for (let item of flows){
      names.push(item.receiverId);
    }
  }
  return names;
})*/
/** 获取拒单原因 **/
const rejectCause = computed(()=>{
  let list = [];
  if (workOrderFlows.value.length > 0){
    const flows =  workOrderFlows.value.filter(item=>item.flowType === Constant.WORK_ORDER_FLOW_TYPE_REJECT)
    for (let i=0; i<flows.length;i++){
      const item = flows[i];
      list.push((i+1)+". ["+item.senderId+"] - "+item.cause);
    }
  }
  return list;
})
/** 获取转单原因 **/
const zhuandanCause = computed(()=>{
  let list = [];
  if (workOrderFlows.value.length > 0){
    const flows =  workOrderFlows.value.filter(item=>item.flowType === Constant.WORK_ORDER_FLOW_TYPE_TRANSFER)
    for (let i=0; i<flows.length;i++){
      const item = flows[i];
      list.push((i+1)+". ["+item.senderId+"] - "+item.cause);
    }
  }
  return list;
})
/** 获取延时原因 **/
const yanshiCause = computed(()=>{
  let list = [];
  if (workOrderFlows.value.length > 0){
    const flows =  workOrderFlows.value.filter(item=>item.flowType === Constant.WORK_ORDER_FLOW_TYPE_DELAY)
    for (let i=0; i<flows.length;i++){
      const item = flows[i];
      list.push((i+1)+". ["+item.senderId+"] - "+item.cause);
    }
  }
  return list;
})
/** 预计解决时间 **/
const plannedDate = computed(()=>{
  let pd = "";
  if (workOrderFlows.value.length > 0){
    const flows =  workOrderFlows.value.filter(item=>item.flowType === Constant.WORK_ORDER_FLOW_TYPE_RECEIVE || item.flowType === Constant.WORK_ORDER_FLOW_TYPE_DELAY);
    for (let item of flows){
      if (item.flowType === Constant.WORK_ORDER_FLOW_TYPE_DELAY){
        pd = item.plannedTime;
      }
    }
    if (!pd){
      pd = flows[0]?.plannedTime;
    }
  }
  return pd;
})

/** 指派确认 **/
const onAssignOK = ()=>{
  formAssignRef.value.validateFields().then(values=>{
    loading.value = true;
    values.workOrderId = workOrder_id.value;
    api_workOrder.assign(values).then(res=>{
      if(res?.data?.code === 0){
        message.success("操作成功",3);
        assign_dialog_visible.value = false;
        isDataChanged.value = true;
        getItem(workOrder_id.value);
      }else{
        if (res?.data?.msg){
          message.error(res?.data?.msg+" "+res?.data?.data,5);
        }
      }
      loading.value = false;
    });
  }).catch(()=>{
    //console.log("onUpdateItemOK  info ---",info);
  });
}
/**
 * 拒单确认
 */
const onRejectOK = ()=>{
  formRejectRef.value.validateFields().then(values=>{
    loading.value = true;
    values.workOrderId = workOrder_id.value;
    api_workOrder.reject(values).then(res=>{
      if(res?.data?.code === 0){
        message.success("操作成功",3);
        reject_dialog_visible.value = false;
        isDataChanged.value = true;
        getItem(workOrder_id.value);
      }else{
        if (res?.data?.msg){
          message.error(res?.data?.msg+" "+res?.data?.data,5);
        }
      }
      loading.value = false;
    });
  }).catch(()=>{
    //console.log("onUpdateItemOK  info ---",info);
  });
}
/** 接单确认 **/
const onReceiveOK = ()=>{
  formReceiveRef.value.validateFields().then(values=>{
    loading.value = true;
    values.workOrderId = workOrder_id.value;
    values.plannedDate = values.plannedDate.format("YYYY-MM-DD HH:mm:ss");
    api_workOrder.receive(values).then(res=>{
      if(res?.data?.code === 0){
        message.success("操作成功",3);
        receive_dialog_visible.value = false;
        isDataChanged.value = true;
        getItem(workOrder_id.value);
      }else{
        if (res?.data?.msg){
          message.error(res?.data?.msg+" "+res?.data?.data,5);
        }
      }
      loading.value = false;
    });
  }).catch(()=>{
    //console.log("onUpdateItemOK  info ---",info);
  });
}
/** 延时确认 **/
const onDelayOK = ()=>{
  formDelayRef.value.validateFields().then(values=>{
    loading.value = true;
    values.workOrderId = workOrder_id.value;
    values.plannedDate = values.plannedDate.format("YYYY-MM-DD HH:mm:ss");
    api_workOrder.delay(values).then(res=>{
      if(res?.data?.code === 0){
        message.success("操作成功",3);
        delay_dialog_visible.value = false;
        isDataChanged.value = true;
        getItem(workOrder_id.value);
      }else{
        if (res?.data?.msg){
          message.error(res?.data?.msg+" "+res?.data?.data,5);
        }
      }
      loading.value = false;
    });
  }).catch(()=>{
    //console.log("onUpdateItemOK  info ---",info);
  });
}
/** 转单确认 **/
const onTransferOK = ()=>{
  formTransferRef.value.validateFields().then(values=>{
    loading.value = true;
    values.workOrderId = workOrder_id.value;
    api_workOrder.transfer(values).then(res=>{
      if(res?.data?.code === 0){
        message.success("操作成功",3);
        transfer_dialog_visible.value = false;
        isDataChanged.value = true;
        getItem(workOrder_id.value);
      }else{
        if (res?.data?.msg){
          message.error(res?.data?.msg+" "+res?.data?.data,5);
        }
      }
      loading.value = false;
    });
  }).catch(()=>{
    //console.log("onUpdateItemOK  info ---",info);
  });
}
/** 协同确认 **/
const onCooperateOK = ()=>{
  //console.log("---",formCooperateState.value.receiver)
  formCooperateRef.value.validateFields().then(values=>{
    loading.value = true;
    values.workOrderId = workOrder_id.value;
    //values.receiver = ['aaa','bbb'];console.log(values)
    api_workOrder.cooperate(values).then(res=>{
      if(res?.data?.code === 0){
        message.success("操作成功",3);
        cooperate_dialog_visible.value = false
        isDataChanged.value = true;
        getItem(workOrder_id.value);
      }else{
        if (res?.data?.msg){
          message.error(res?.data?.msg+" "+res?.data?.data,5);
        }
      }
      loading.value = false;
    });
  }).catch(()=>{
    //console.log("onUpdateItemOK  info ---",info);
  });
}
/** 完成确认 **/
const onFinishOK = ()=>{
  formFinishRef.value.validateFields().then(values=>{
    loading.value = true;
    values.workOrderId = workOrder_id.value;
    values.faultOverView = workOrder.value.faultOverview;
    if (finishSelectAsset.value.id){
      values.assetId = finishSelectAsset.value.id;
    }

    const formData = new FormData();
    fileList.value.forEach((item)=>{
      formData.append('file', item);
    });
    for(let key  in values){  //遍历对象key,value
      formData.append(key,values[key]);
    }

    api_workOrder.finish(formData).then(res=>{
      if(res?.data?.code === 0){
        message.success("操作成功",3);
        finish_dialog_visible.value = false;
        isDataChanged.value = true;
        getItem(workOrder_id.value);
      }else{
        if (res?.data?.msg){
          message.error(res?.data?.msg+" "+res?.data?.data,5);
        }
      }
      loading.value = false;
    });
  }).catch(()=>{
    //console.log("onUpdateItemOK  info ---",info);
  });
}
/** 评价确认 **/
const onCommentOK = ()=>{
  formCommentRef.value.validateFields().then(values=>{
    //loading.value = true;
    values.workOrderId = workOrder_id.value;
    if (commentTagState.selectedTags && commentTagState.selectedTags.length > 0){
      const selTags = JSON.stringify(commentTagState.selectedTags)
      values.tags = selTags;
    }
    api_workOrder.comment(values).then(res=>{
      if(res?.data?.code === 0){
        message.success("操作成功",3);
        comment_dialog_visible.value = false;
        isDataChanged.value = true;
        getItem(workOrder_id.value);
      }else{
        if (res?.data?.msg){
          message.error(res?.data?.msg+" "+res?.data?.data,5);
        }
      }
      loading.value = false;
    });

  }).catch(()=>{
    //console.log("onUpdateItemOK  info ---",info);
  });
}
/**
 * 撤销工单
 */
const cancelWorkOrder = ()=>{
  Modal.confirm({
    title: '撤销？',
    icon: createVNode(ExclamationCircleOutlined),
    content: '确定要撤销工单吗？本次工单将会删除！',
    okText: '确认',
    cancelText: '取消',
    onOk() {
      return new Promise((resolve, reject) => {
        api_workOrder.cancel(workOrder_id.value).then(res=>{
          if(res?.data?.code === 0){
            message.success("操作成功",3);
            resolve();
            isDataChanged.value = true;
            visible_viewWorkOrder.value = false;
          }else{
            reject();
            if (res?.data?.msg){
              message.error(res?.data?.msg+" "+res?.data?.data,5);
            }
          }
        });
      }).catch(() => {});
    },
  });
}

/**
 * 文件上传列表中的删除操作
 * @param file file
 */
const handleFileRemove = file => {
  const index = fileList.value.indexOf(file);
  const newFileList = fileList.value.slice();
  newFileList.splice(index, 1);
  fileList.value = newFileList;
};
/**
 * 文件上传前的过滤
 * @param file file
 * @returns {boolean}
 */
const beforeUpload = file => {
  if (fileList.value.length+1 > 5){
    message.error("上传文件数量最多为5个");
    return false;
  }
  let flg = fileUpload.isUploadFileType(file.type);
  if (!flg){
    message.error(`${file.name} : 不支持的上传文件类型`);
    return false;
  }
  flg = fileUpload.isAllowedFileSize(file.size);
  if (!flg){
    message.error(`${file.name} : 文件过大，请限制容量在5M以内`);
    return false;
  }

  fileList.value.push(file);
  return false;
};
/**
 * 图片预览
 * @param fileFullName 完整文件名，e.g:"aaa.jpg"
 * @param fileFolderName 所在文件夹名
 */
const showPreviewImg = (fileFullName,fileFolderName) => {
  const imgSrc = window.product.file_url_path+fileFolderName+"/"+fileFullName;
  imgPreviewRef.value.show(imgSrc);
};
/** 除图片格式的文件打开 **/
const showOtherFile = (fileFullName,fileFolderName) => {
  const imgSrc = window.product.file_url_path+fileFolderName+"/"+fileFullName;
  window.open(imgSrc, "_blank");
};

/**
 * 转单接收人回调的选择项的value值
 * @param val 选择项的value值
 */
const callback_selDeptUser=(val)=>{
  formTransferState.value.receiver = val;
}
/**
 * 指派接收人回调的选择项的value值
 * @param val 选择项的value值
 */
const callback_selDeptUser_assign = (val)=>{
  formAssignState.value.receiver = val;
}
/**
 * 协同人回调的选择项的value值
 * @param val 选择项的value值
 */
const callback_selDeptUserOfxietong=(val)=>{
  formCooperateState.value.receiver = val;
}
/**
 * 获取所有故障类型
 */
const findAllFaultType = ()=>{
  treeSelectAllFaultType.value = [];
  api_faultType.findTenantAllFaultType().then(res=>{
    if (res?.data?.code === 0) {
      const list = res.data.data;
      if (list){
        for (let item of list){
          treeSelectAllFaultType.value.push({title:item.name,value:item.id});
        }
      }
    }else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
  });
}
/**
 * 获取所有转单类型
 */
const findAllTransferOrderType = ()=>{
  selectAllTransferTypeOptions.value = [];
  api_transferOrderType.findAllSysAndTenantTransferOrderType().then(res=>{
    if (res?.data?.code === 0) {
      const list = res.data.data;
      if (list){
        for (let item of list){
          selectAllTransferTypeOptions.value.push({label:item.name,value:item.id});
        }
      }
    }else {
      if (res?.data?.msg) {
        message.error(res?.data?.msg + " " + res?.data?.data, 5);
      }
    }
  });
}
const setCommentCode = (val)=>{
  formCommentState.value.code = val;
  commentTagState.selectedTags = [];
  formCommentState.value.content = "";
  if (val === Constant.COMMENT_CODE_BAD){
    comment_content_visible.value = true;
  }else {
    comment_content_visible.value = false;
  }
}
const handleCommentTagChange = (tag, checked) => {
  const {
    selectedTags,
  } = commentTagState;
  const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
  //console.log('You are interested in: ', nextSelectedTags);
  commentTagState.selectedTags = nextSelectedTags;
};

/**
 * 显示选择资产弹窗
 */
const showListAssetDialog = ()=>{
  listAssetDialogRef.value.show();
}
/**
 * 工单完成弹窗中选择的资产后的回调
 * @param selectAsset 资产实体数据
 */
const callbackSelAsset = (selectAsset)=>{
  finishSelectAsset.value = selectAsset.value;
}


defineExpose({
  show
})

</script>

<style scoped>
/* a-descriptions #title style */
:deep(.ant-descriptions-header) {
  margin-bottom: 0!important;
}
:deep(.ant-descriptions-item-label){
  padding: 8px 10px!important;
}
.desc_title {
  background-color: #f0f0f0;
  padding: 8px 10px;
  font-size:15px;
  border: #f0f0f0 1px solid;
  border-bottom: 0;
}
:deep(.ant-descriptions-row th) {
  width: 100px;
}
:deep(.ant-steps-item-description) {
  max-width: none!important;
}
/* comment tag custom style -- start */
:deep(.ant-tag-checkable){
  background-color: #f1f1f1;
  color: #919191;
}
:deep(.ant-tag-checkable:hover){
  color: #ec8806;
}
:deep(.ant-tag-checkable-checked){
  background-color: #fcf2dc;
  color: #e18201;
}
/* comment tag custom style -- end */
:deep(.cmp-in) {
  animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.3s; /* don't forget to set a duration! */
}
:deep(.cmp-out) {
  animation: fadeInOut; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.3s; /* don't forget to set a duration! */
}
</style>
