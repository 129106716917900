<template>
  <div class="container">
    <div class="item_left">
      <img class="imgBg" src="@/assets/img_login_bg.jpg">
      <div class="item">
        <div class="logoDiv">
          <img class="imgLogo" src="../../public/logo/logo.png">
          <div class="logoName">{{ productName }}</div>
          <div class="logoDesc">集运维资源监控、运维工单处理、运维报表、运维知识库、运维展现中心为一体的综合运维流程管理平台</div>
        </div>

        <div class="title">
          <div class="name">博观而约取 厚积而薄发</div>
        </div>
      </div>
    </div>

    <div class="item_right">
      <img class="imgBg" src="@/assets/img_login_bg.jpg">
      <div></div>
      <div class="loginDivs">
        <transition mode="out-in" leave-active-class="cmp-out" enter-active-class="cmp-in">
          <Component :is="cmpName" @changeCmp="setChangeCmp"/>
        </transition>
      </div>
      <div class="companyName">{{ productCompanyName }}</div>
    </div>
  </div>
</template>

<script>
import {defineComponent,ref, onMounted} from 'vue';
import _login from "@/components/_login";
import _register from "@/components/_register";
import '../../node_modules/animate.css/animate.css';
import {Constant} from "../js/common";

export default defineComponent({
  computed: {
    Constant() {
      return Constant
    }
  },
  components: {
    _login,
    _register
  },

  setup() {
    const cmpName = ref("_login");

    const productName = ref(window.product.PRODUCT_NAME);
    const productCompanyName = ref(window.product.PRODUCT_COMPANY_NAME)

    onMounted(()=>{

    })
    /**
     * 子组件调用此方法切换
     * @param val 子组件名
     */
    const setChangeCmp = (val)=>{
      cmpName.value = val;
    }

    return {
      cmpName,
      productName,
      productCompanyName,
      setChangeCmp
    };
  },



});
</script>

<style scoped>
  .container{
    display: flex;
    align-items: stretch;
    height: 100vh;
  }
  .item_left{
    flex: 3;
    display: flex;
    /*background: url("@/assets/img_login_bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;*/
    /*filter: brightness(50%);
    animation: left_bg_animation 1s;*/
    position: relative;
  }

  .item_left .imgBg{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    filter: brightness(50%);
    animation: bg_animation 3s;
  }
  .item{
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .item .logoDiv{
    flex: 1;
    display: flex;
    z-index: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: left_logo 3s;
  }
  .item .logoDiv .imgLogo{
    width: 110px;
    height: 110px;
    margin: 10px;
  }
  .item .logoDiv .logoName{
    font-size: 20px;
    color: #fff;
  }
  .item .logoDiv .logoDesc{
    font-size: 14px;
    padding: 20px 30px;
    color: #fff;
  }
  .item .title{
    flex: 2;
    display: flex;
    align-self: center;
    z-index: 1;
  }
  .item .title .name{
    margin: auto;
    color: #fff;
    font-size: 30px;
    font-weight: normal;
    text-shadow: 0px 0px 15px #409eff;
    opacity: 1;
    animation: left_title_name 2s;
  }
  .item_right{
    background-color: #f5f5f5;
    flex: 4;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
  }
  .item_right .imgBg{
    display: none;
  }
.loginDivs{
  width: 50%;
  min-width: 300px;
  margin: auto;
  animation: loginDivs 2s;
}
  .companyName{
    padding: 10px;
    z-index: 1;
  }

@keyframes bg_animation {
  from{
    filter: brightness(100%);
  }
  to{
    filter: brightness(50%);
  }
}
@keyframes left_logo {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@keyframes loginDivs {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@keyframes left_title_name {
  from{
    opacity: 0;
    font-size: 1px;
    text-shadow: 0px 0px 0px #409eff;
  }
  to{
    opacity: 1;
    font-size: 30px;
    text-shadow: 0px 0px 15px #409eff;
  }
}

@media screen and (max-width: 950px){
  .item_left{
    flex: 0;
    display: none;
  }
  .item_right{
    flex: 1;
  }
  .item_right .imgBg{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    filter: brightness(50%);
    animation: bg_animation 3s;
  }
  .loginDivs{
    width: 70%;
    z-index: 1;
    max-width: 400px;
  }
  .companyName{
    color: #fff;
  }
}
@media  screen and (max-height: 500px){
  .item .logoDiv{
    flex: 1;
  }
  .item .title{
    flex: 0;
    display: none;
  }
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#components-form-demo-normal-login .login-form-forgot {
  margin-bottom: 24px;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

  .cmp-in {
    animation: zoomIn; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 0.3s; /* don't forget to set a duration! */
  }
  .cmp-out {
    animation: zoomOut; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 0.3s; /* don't forget to set a duration! */
  }
</style>
