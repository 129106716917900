<template>
  <div>
    <div>
      <img src="@/assets/screenbg.png" class="bg-img" alt=""  />
      <div class="bg-blur"></div>
    </div>

    <div class="container">
      <div class="header" >
        <Header></Header>
      </div>

      <div class="main" >

        <div class="left" >
          <div class="one">
            <LeftOne ref="leftOneRef"></LeftOne>
          </div>
          <div class="two">
            <LeftTwo ref="leftTwoRef"></LeftTwo>
          </div>
          <div class="three">
            <LeftThree ref="leftThreeRef"></LeftThree>
          </div>
        </div>

        <div class="middle" >
          <div class="map" >
            <!--    map组件     -->
            <Map ref="mapRef" ></Map>
          </div>

          <div class="woList" >
            <!--  WorkOrderList组件-->
            <WorkOrderList ref="woListRef" ></WorkOrderList>
          </div>
        </div>

        <div class="right" >
          <div class="one">
            <!--  RightOne组件-->
            <RightOne ref="rightOneRef"></RightOne>
          </div>
          <div class="two">
            <!--  RightTwo组件-->
            <RightTwo ref="rightTwoRef"></RightTwo>
          </div>
          <div class="three">
            <!--  RightThree组件-->
            <RightThree ref="rightThreeRef"></RightThree>
          </div>
        </div>

      </div>

    </div>

    <!--    工单查看     -->
    <ViewWorkOrder :workOrderId="viewWorkOderId" @callback="callback_viewWorkOrder" v-if="isShowViewWorkOrder" ></ViewWorkOrder>

  </div>

</template>

<script setup>
import {createVNode, defineAsyncComponent, onMounted, onUnmounted,provide, ref} from "vue";
import api_sse from "@/api/sse";
import {message, Modal} from "ant-design-vue";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
import {useUserStore} from "@/framework/store/UserStore";
import {Constant} from "@/js/common";
import { EventSourcePolyfill } from "event-source-polyfill";

const ViewWorkOrder = defineAsyncComponent(() =>{
  return import('@/components/monitorPage/_viewWorkOrder.vue')
})

/**
 * 子调父，_map.vue,_workOrderList.vue 中inject("viewWorkOrder"); 调用 打开查看工单
 */
provide("viewWorkOrder",(id)=>{
  viewWorkOderId.value = id;
  isShowViewWorkOrder.value = true;
});

const userStore = useUserStore();
let sseSource;
const leftOneRef = ref(null);
const leftTwoRef = ref(null);
const leftThreeRef = ref(null);
const rightOneRef = ref(null);
const rightTwoRef = ref(null);
const rightThreeRef = ref(null);
const mapRef = ref(null);
const woListRef = ref(null);
let heartTimer;
let resizeTimer;
const viewWorkOderId = ref(null);       //要查看工单的id
const isShowViewWorkOrder = ref(false);

const Header = defineAsyncComponent(() =>{
  return import('@/components/monitorPage/_header.vue')
})
const Map = defineAsyncComponent(() =>{
  return import('@/components/monitorPage/_map.vue')
})
const WorkOrderList = defineAsyncComponent(() =>{
  return import('@/components/monitorPage/_workOrderList.vue')
})
const LeftOne = defineAsyncComponent(() =>{
  return import('@/components/monitorPage/_leftOne.vue')
})
const LeftTwo = defineAsyncComponent(() =>{
  return import('@/components/monitorPage/_leftTwo.vue')
})
const LeftThree = defineAsyncComponent(() =>{
  return import('@/components/monitorPage/_leftThree.vue')
})
const RightOne = defineAsyncComponent(() =>{
  return import('@/components/monitorPage/_rightOne.vue')
})
const RightTwo = defineAsyncComponent(() =>{
  return import('@/components/monitorPage/_rightTwo.vue')
})
const RightThree = defineAsyncComponent(() =>{
  return import('@/components/monitorPage/_rightThree.vue')
})

onMounted(()=>{
  initEventSource();
  window.addEventListener("resize",setResize);
})
onUnmounted(()=>{
  colseEventSource();
  window.removeEventListener("resize",setResize);
  if (heartTimer){
    clearInterval(heartTimer);
  }
})
/**
 * 定时请求后端方法，避免token过期
 */
const heart = ()=>{
  api_sse.heart(Constant.SSE_TYPE_MONITOR,Constant.SSE_TYPE_MONITOR).then(res=>{
    if(res?.data?.code === 0){
      //console.log(res.data);

    }else{
      if (res?.data?.msg){
        message.error(res?.data?.msg+" "+res?.data?.data,5);
      }
    }

  });
}
/**
 * 是否隐藏显示内容主体
 * <br/> 主要作用：缩放浏览器时图表会撑破样式高度导致不能自由缩放高度，解决方式缩放浏览器时先隐藏图表缩放结束后再显示图表
 * <br/> 其中定时器作用：在设置的超时时间触发增加延迟时间
 */
const setResize = ()=>{
  leftOneRef.value.setInfoVisible(false);
  leftTwoRef.value.setInfoVisible(false);
  leftThreeRef.value.setInfoVisible(false);
  rightOneRef.value.setInfoVisible(false);
  rightTwoRef.value.setInfoVisible(false);
  rightThreeRef.value.setInfoVisible(false);
  woListRef.value.setInfoVisible(false);

  if (resizeTimer){
    clearTimeout(resizeTimer);
  }
  resizeTimer = setTimeout(()=>{

    leftOneRef.value.setInfoVisible(true);
    leftTwoRef.value.setInfoVisible(true);
    leftThreeRef.value.setInfoVisible(true);
    rightOneRef.value.setInfoVisible(true);
    rightTwoRef.value.setInfoVisible(true);
    rightThreeRef.value.setInfoVisible(true);
    woListRef.value.setInfoVisible(true);

    mapRef.value.resetViewport(); //重置地图可视窗口居中显示信息点

  }, 100);
}
/**
 * 初始化sse
 */
const initEventSource = ()=>{
  if (!window.EventSource){
    Modal.confirm({
      title: '系统提示',
      icon: createVNode(ExclamationCircleOutlined),
      content: '您的浏览器不支持SSE特性，将无法动态更新监控页面数据，请升级或更换最新的浏览器！',
      okText: '确定',
      cancelText: '取消',
      onOk() {
      },
      onCancel() {
      },
    });
    return;
  }
  //sseSource = new EventSource(config.server_url+"sse/connect?id="+userStore.token);
  /**
   * 连接sse,服务端sse 连接key 由 SSE_TYPE_?+分隔符+id 组成
   * @type '类型Constant.SSE_TYPE_?'
   * @id 用户token
   */
  sseSource = new EventSourcePolyfill(window.product.server_url+"sse/connect?type="+Constant.SSE_TYPE_MONITOR+"&id="+Constant.SSE_TYPE_MONITOR, {
    headers: {
      'Authorization': userStore.token,  //第三方sse可以传递header
      'Device': Constant.LOGIN_DEVICE_TYPE_PC
    },
    heartbeatTimeout: 10*60*1000      //默认重连间隔：45000毫秒，此设置10分钟
  });

  sseSource.onopen = ()=>{
    console.log("onopen=>")
    if (heartTimer){
      clearInterval(heartTimer);
    }
    /* 定时请求后端sse心跳方法，避免heartbeatTimeout过期重连，同时此方法后端已加入拦截同时也会避免token过期 **/
    heartTimer = setInterval(heart,5*60*1000); //毫秒，时间不能大于heartbeatTimeout
  }
  sseSource.onmessage = function(event){
    console.log("onmessage=>",event)
  }
  sseSource.onerror = (event)=>{
    console.log('connection state: ' + sseSource.readyState + ', error: ' + event);
  }
  /**
   * 监听sse - 工单状态改变
   */
  sseSource.addEventListener(Constant.SSE_EVENT_NAME_WO_CHANGE,(obj)=>{
    console.log("sse addEventListener=>",obj);

    leftOneRef.value.refresh();
    leftTwoRef.value.refresh();
    leftThreeRef.value.refresh();
    rightOneRef.value.refresh();
    rightTwoRef.value.refresh();
    rightThreeRef.value.refresh();
    mapRef.value.refresh();
    woListRef.value.refresh();
  })
}
/**
 * 关闭sse及移除服务端sse
 */
const colseEventSource = ()=>{
  sseSource.close();
  api_sse.closeSSE(Constant.SSE_TYPE_MONITOR,Constant.SSE_TYPE_MONITOR);
}
/**
 * 查看工单关闭后回调
 */
const callback_viewWorkOrder = ()=>{
  isShowViewWorkOrder.value = false;
}


</script>

<style scoped>
.bg-img{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;

}
.bg-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
}

.container{
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  padding: 5px;
}
.header{
  height: 55px;
  margin: 5px;
}
.main{
  flex:1;
  display: flex;

}
.left{
  flex:1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.middle{
  flex:2;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.right{
  flex:1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.map{
  flex: 2;
  margin: 7px;
}
.woList{
  flex: 1;
  margin: 7px;
}
.left .one{
  flex: 1;
  margin: 7px;
}
.left .two{
  flex: 1;
  margin: 7px;
}
.left .three{
  flex: 1;
  margin: 7px;
}
.right .one{
  flex: 1;
  margin: 7px;
}
.right .two{
  flex: 1;
  margin: 7px;
}
.right .three{
  flex: 1;
  margin: 7px;
}

</style>