import {ref} from 'vue';


/** 监听项目管理设置活动的（_projectSelect.vue监听） **/
const NavSelProjectVal = ref("");

/** 流转状态属性 **/
const flowTypeObj = {0:'报障',1:'受理',2:'指派',3:'拒单',4:'接单',7:'到场', 5:'转单', 6:'延时', 8:'完成'};

/** 常量类 **/
const Constant = {
    //设备类型：pc
    LOGIN_DEVICE_TYPE_PC : 0,

    //报障
    WORK_ORDER_FLOW_TYPE_REPORT : 0,
    //派单
    WORK_ORDER_FLOW_TYPE_DISPATCH : 1,
    //指派
    WORK_ORDER_FLOW_TYPE_ASSIGN : 2,
    //拒单
    WORK_ORDER_FLOW_TYPE_REJECT : 3,
    //接单
    WORK_ORDER_FLOW_TYPE_RECEIVE : 4,
    //转单
    WORK_ORDER_FLOW_TYPE_TRANSFER : 5,
    //延时
    WORK_ORDER_FLOW_TYPE_DELAY : 6,
    //到场
    WORK_ORDER_FLOW_TYPE_ARRIVED : 7,
    //完成
    WORK_ORDER_FLOW_TYPE_FINISHED : 8,

    //个人中心 - 我的工单 筛选条件 我创建：1
    MY_WORK_ORDER_CREATED : 1,
    //个人中心 - 我的工单 筛选条件 待我处理的：2
    MY_WORK_ORDER_PENDING : 2,
    //个人中心 - 我的工单 筛选条件 我报障的：3
    MY_WORK_ORDER_REPORT : 3,
    //个人中心 - 我的工单 筛选条件 我已完成的：4
    MY_WORK_ORDER_FINISHED : 4,
    //个人中心 - 我的工单 筛选条件 待评价：7
    MY_WORK_ORDER_UNCOMMENT : 7,

    /** 工单中心 - 已完成 **/
    WORK_ORDER_ALL_FINISHED : 5,
    /** 工单中心 - 未完成 **/
    WORK_ORDER_ALL_UNFINISHED : 6,
    /** 工单中心 - 所有的 **/
    WORK_ORDER_ALL : 8,

    /** 附件类型：工单问题描述 **/
    ATTACH_FILE_TYPE_WO_CREATE : 0,
    /** 附件类型：工单解决方案 **/
    ATTACH_FILE_TYPE_WO_RESOLVE : 1,
    /** 附件类型：巡检 **/
    ATTACH_FILE_TYPE_INSPECT : 2,
    /** 附件类型：知识库故障描述文件 **/
    ATTACH_FILE_TYPE_KBS_FAULT : 3,
    /** 附件类型：知识库解决方案文件 **/
    ATTACH_FILE_TYPE_KBS_SOLUTION : 4,
    /** 附件类型：定时任务 **/
    ATTACH_FILE_TYPE_TASK_EXECUTE : 5,
    /**  附件类型：工单语音描述文件 **/
    ATTACH_FILE_TYPE_WO_AUDIO : 6,

    //巡检状态 - 正常
    INSPECT_STATUS_NORMAL : 0,
    //巡检状态 - 异常
    INSPECT_STATUS_UNNORMAL : 1,

    //工单评论 - 满意
    COMMENT_CODE_OK : 1,
    //工单评论 - 不满意
    COMMENT_CODE_BAD : 2,

    /** 报表统计-查询类型type-年 **/
    STATEMENT_TYPE_YEAR : '0',
    /** 报表统计-查询类型type-月 **/
    STATEMENT_TYPE_MONTH : '1',
    /** 报表统计-查询类型type-日 **/
    STATEMENT_TYPE_DAY : '2',

    /** message消息类型：工单 **/
    MESSAGE_TYPE_WORKORDER : 1,
    /** message消息类型：资产 **/
    MESSAGE_TYPE_ASSET : 2,
    /** message消息状态：未读 **/
    MESSAGE_STATUS_UNREAD: 0,
    /** message消息类型：已读 **/
    MESSAGE_STATUS_READED:1,

    USER_TYPE_ROOT : 0,                 //用户类型root
    USER_TYPE_ADMIN : 1,                //用户类型租户管理员
    USER_TYPE_GENERAL : 2,              //用户类型普通用户
    USER_TYPE_VISITOR : 3,              //用户类型访客
    USER_PROPERTY_ISRECEIVER : 1,       //用户是否为工单接收人
    USER_PROPERTY_ISASSIGN_NO : 0,      //是否拥有工单指派功能，0：否，1：是
    USER_PROPERTY_ISASSIGN_YES : 1,     //是否拥有工单指派功能，0：否，1：是

    /** websocket 过期code **/
    WEBSOCKET_CODE_EXPIRED : -1,
    /** websocket 心跳code **/
    WEBSOCKET_CODE_HEART : 0,
    /** websocket 信息 **/
    WEBSOCKET_CODE_INFO  : 1,

    /** 资产-状态-未使用 **/
    ASSET_STATUS_UNUSED : 0,
    /** 资产-状态-使用的 **/
    ASSET_STATUS_USED : 1,
    /** 资产-状态-已核销 **/
    ASSET_STATUS_CANCELED : 2,

    /** 知识库-审批状态：待审批 **/
    KBS_APPROVAL_STATUS_PENDING : 0,
    /** 知识库-审批状态：已通过 **/
    KBS_APPROVAL_STATUS_PASSED : 1,
    /** 知识库-审批mode：已拒绝 **/
    KBS_APPROVAL_STATUS_REJECTED : 2,
    /** 知识库-审批mode：单审通过 **/
    KBS_APPROVAL_MODE_ONE : 0,
    /** 知识库-审批mode：全审通过(无层级) **/
    KBS_APPROVAL_MODE_ALL_UNORDER : 1,
    /** 知识库-审批mode：全审通过(有层级) **/
    KBS_APPROVAL_MODE_ALL_ORDER : 2,
    /** 知识库-审批mode：多审通过 **/
    KBS_APPROVAL_MODE_MORE : 3,

    /** websocket 消息code - 工单状态改变的 **/
    WEBSOCKET_INFORM_CODE_WORKORDER_STATUS_CHANGEED : 1000,
    /** websocket 消息code - Message消息的变更 **/
    WEBSOCKET_INFORM_CODE_MESSAGE_STATUS_CHANGE : 1001,
    /** websocket 消息code - 资产状态改变的 **/
    WEBSOCKET_INFORM_CODE_ASSET_STATUS_CHANGE : 1002,

    /** sse连接类型大屏监控页面使用 **/
    SSE_TYPE_MONITOR : 'monior',
    /** sse连接类型页面中状态使用 **/
    SSE_TYPE_STATUS : 'status',
    /** sse 事件名称 - 工单状态改变 **/
    SSE_EVENT_NAME_WO_CHANGE : 'SSE_EVENT_NAME_WO_CHANGE',
}

/** 文件上传相关方法 **/
const fileUpload = {
    /**
     * 判断文件上传支持的类型（包括图片、文档）
     * @param type 类型
     * @returns {boolean}
     */
    isUploadFileType:function (type){
        const fileTypes=['image/gif',"image/bmp","image/apng",'image/jpeg','image/jpg','image/pjpeg','image/x-png','image/png',
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ];
        const flg = fileTypes.includes(type.toLowerCase());
        if (flg){
            return true;
        }
        return false;
    },
    /**
     * 判断文件上传的文件是否是支持的图片类型
     * @param type 类型
     * @returns {boolean}
     */
    isUploadFileImgType:function (type){
        const fileTypes=['image/gif',"image/bmp","image/apng",'image/jpeg','image/jpg','image/pjpeg','image/x-png','image/png'];
        const flg = fileTypes.includes(type.toLowerCase());
        if (flg){
            return true;
        }
        return false;
    },
    /**
     * 判断文件上传限制的大小
     * @param size 上传文件的大小
     * @returns {boolean}
     */
    isAllowedFileSize:function (size){
        const flg = size / 1024 / 1024 <= 5;     //to 5M
        if (flg){
            return true;
        }
        return false;
    },
    /**
     * 判断是否是图片格式后缀
     * @param fileFullName 完整的文件名
     * @returns {boolean} bool
     */
    isImgSuffix:(fileFullName)=>{
        const fileTypes=['gif',"bmp","png",'jpeg','jpg','png'];
        let index= fileFullName.lastIndexOf(".");
        if (!index){
            return false;
        }
        let ext = fileFullName.substr(index+1);
        const flg = fileTypes.includes(ext.toLowerCase());
        if (flg){
            return true;
        }
        return false;
    },

}



export {
    NavSelProjectVal,
    flowTypeObj,
    fileUpload,
    Constant
}
